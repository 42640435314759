import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Seo from "../components/seo"
import Layout from "../components/Layout"
import SectionUnits from "../components/Section/SectionUnits"
import WppButtonFloat from "../components/Button/WppButtonFloat"

const UnitsPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout>
      <Seo title="Unidades" />
      <SectionUnits data={data.strapiUnitpage.state} />
      <WppButtonFloat />
    </Layout>
  )
}

const query = graphql`
  query {
    strapiUnitpage {
      state {
        state
        title
        units {
          address
          city
          email
          id
          openingHours
          mapUrl
          phone
          whatsapp
        }
      }
    }
  }
`

export default UnitsPage
