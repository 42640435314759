import * as React from "react"

import { Container } from "../../../styles/container"
import { SectionHeader, SectionContent, SectionTitle } from "../"
import SectionHero from "../SectionHero"
import SVGMap from "../../SVGMap"

import * as S from "./styled"

const SectionUnits = props => {
  const { data } = props

  return (
    <S.Section>
      <SectionHeader>
        <SectionHero>
          <Container>
            <SectionTitle as="h1" medium noMargin colorInverse>
              Unidades
            </SectionTitle>
          </Container>
        </SectionHero>
      </SectionHeader>

      <SectionContent className="s-content">
        <Container>
          <SVGMap data={data} />
        </Container>
      </SectionContent>
    </S.Section>
  )
}

export default SectionUnits
