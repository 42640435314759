import styled from "styled-components"

import { Section as SectionComponent } from "../"
import { breakpoints, sizes } from "../../../styles/breakpoints"

export const Section = styled(SectionComponent)`
  padding: 0 0 50px;

  .s-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;
    height: calc(100vh - 280px);
    min-height: 650px;

    @media ${breakpoints.lessThan(sizes.desktop)} {
      min-height: 550px;
    }

    @media ${breakpoints.lessThan(sizes.tablet)} {
      display: block;
      min-height: auto;
      height: auto;
    }

    .paragraph {
      line-height: 1.5;
      &:not(:last-of-type) {
        margin-bottom: 15px;
      }
    }
  }
`
