import styled from "styled-components"

import { sizes, breakpoints } from "../../styles/breakpoints"

export const Wrapper = styled.div`
  @media ${breakpoints.lessThan(sizes.phone)} {
    margin-top: -60px;
  }
  @media ${breakpoints.lessThan(sizes.smaller)} {
    margin-top: -80px;
  }

  .svg-map {
    width: 475px;
    height: 475px;
    background-color: transparent;
    margin: auto;
    display: block;
    transform: scale(1.3);

    @media ${breakpoints.lessThan(sizes.desktop)} {
      transform: scale(1.1);
    }
    @media ${breakpoints.lessThan(sizes.tablet)} {
      transform: scale(1);
    }
    @media ${breakpoints.lessThan(sizes.phone)} {
      transform: scale(0.85);
    }
    @media ${breakpoints.lessThan("520px")} {
      transform: scale(0.85);
      margin-left: -20px;
    }
    @media ${breakpoints.lessThan("442px")} {
      transform: scale(0.8);
      margin-left: -50px;
    }
    @media ${breakpoints.lessThan("392px")} {
      transform: scale(0.75);
      margin-left: -70px;
    }
    @media ${breakpoints.lessThan("360px")} {
      transform: scale(0.7);
      margin-left: -85px;
    }
    @media ${breakpoints.lessThan("339px")} {
      transform: scale(0.65);
      margin-left: -90px;
    }

    .shape {
      fill: var(--color-grayscale4);
      transition: all 0.2s linear;
      opacity: 0.8;
    }

    .label-state {
      user-select: none;
      fill: var(--color-grayscale5);
      font-size: 0.7rem;
      font-weight: bold;
    }

    .icon-state {
      fill: var(--color-grayscale4);
      stroke: var(--color-white);
      stroke-width: 1;
      transition: all 0.2s linear;
    }

    .state {
      &:hover {
        .shape {
          stroke-width: 1;
          stroke: white;
        }
      }

      &.-active {
        .shape,
        .icon-state {
          opacity: 1;
          fill: var(--color-primary);
        }

        .icon-state {
          stroke: var(--color-white);
        }

        .label-state {
          fill: var(--color-white);
        }

        &:hover {
          .shape {
            opacity: 0.9;
          }

          .icon-state {
            opacity: 0.9;
          }
        }
      }
    }
  }

  .map-status {
    margin-top: -15px;

    .list.-status {
      .item {
        display: flex;
        align-items: center;
        &:not(:last-of-type) {
          margin-bottom: 10px;
        }

        .circle {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background-color: var(--color-grayscale4);
          margin-right: 8px;
        }

        .status {
          padding-top: 2px;
          font-size: 0.9rem;
          color: var(--color-grayscale5);
          font-weight: 600;
        }

        &.-active {
          .circle {
            background-color: var(--color-primary);
          }
        }
      }
    }

    .legend {
      margin-top: 20px;
      font-size: 0.85rem;
      color: var(--color-grayscale5);
      font-weight: normal;
      @media ${breakpoints.lessThan(sizes.smaller)} {
        font-size: 0.79rem;
      }
    }
  }
`

export const ModalInside = styled.div`
  .modal-header {
    /* position: relative; */

    .modal-title {
      font-size: 1.5rem;
      font-weight: bold;
      color: var(--color-primary);
    }

    .modal-subtitle {
      margin-top: 3px;
      font-size: 1.2rem;
      color: var(--color-secondary);
      font-weight: bold;
    }

    .btn-close {
      position: absolute;
      top: 8px;
      right: 8px;
      transition: transform ease 0.2s;

      .icon {
        width: 25px;
        height: 25px;
        color: var(--color-primary);
      }

      &:hover {
        transform: scale(1.4);
      }
    }
  }

  .modal-content {
    margin-top: 10px;
    padding-top: 20px;
    border-top: 2px solid var(--color-grayscale3);

    .box-unit {
      &:not(:last-of-type) {
        margin-bottom: 22px;
      }

      .title {
        font-size: 1rem;
        font-weight: bold;
        color: var(--color-black);
        margin-bottom: 6px;
        text-transform: uppercase;
      }

      .paragraph {
        font-size: 0.95rem;
        font-weight: 500;
        color: var(--color-grayscale5);
        line-height: 1.4;

        &:not(:last-of-type) {
          margin-bottom: 8px;
        }

        .highlight {
          font-weight: normal;
          color: var(--color-primary);
        }

        .items {
          display: inline-block;

          a,
          span {
            &:not(:last-of-type) {
              margin-right: 5px;

              &::after {
                content: ",";
              }
            }
          }
        }

        a {
          color: var(--color-secondary);

          &:hover {
            opacity: 0.7;
          }
        }
      }

      .box-downloads-wrapper {
        margin-top: 20px;

        .title {
          margin-bottom: 10px;
        }

        .btn-wrapper {
          display: flex;
          flex-direction: column;
          max-width: max-content;

          .btn {
            &:not(:last-of-type) {
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }

  .modal-action {
    margin-top: 35px;

    &.-center {
      text-align: center;
    }
  }
`
